import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  Home,
  FileText,
  Settings,
  LogOut,
  MessageSquare,
  LayoutDashboard,
  Sun,
  Moon,
} from "lucide-react";
import { useGetUserDataQuery } from "../../features/user/userApiSlice";
import { logOut } from "../../features/auth/authSlice";
import cultzymeLogoDark from "../../assets/images/ctz-logo-white-black.png";
import cultzymeLogoLight from "../../assets/images/cultzyme-short-logo.png";
import { useTheme } from "../ThemeProvider";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="absolute left-full ml-2 px-2 py-1 bg-primary text-white text-xs font-medium rounded whitespace-nowrap z-50"
            initial={{ opacity: 0, x: -5 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -5 }}
            transition={{ duration: 0.2 }}
          >
            {text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ThemeToggle = ({ className }) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className={className}
      aria-label="Toggle theme"
    >
      <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
      <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
      <span className="sr-only">Toggle theme</span>
    </button>
  );
};

const MainNavigation = () => {
  const { data } = useGetUserDataQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const { theme } = useTheme();

  const handleLogout = () => {
    dispatch(logOut());
  };

  const navLinkClass = (path) => {
    const isActive = location.pathname === path;
    return `flex items-center justify-center w-10 h-10 rounded-md text-muted-foreground transition-colors duration-200
      ${
        isActive
          ? "bg-primary text-primary-foreground"
          : "hover:bg-accent hover:text-accent-foreground"
      }`;
  };

  const NavLink = ({ to, icon: Icon, text }) => (
    <Tooltip text={text}>
      <Link to={to} className={navLinkClass(to)}>
        <Icon className="h-[1.2rem] w-[1.2rem]" />
      </Link>
    </Tooltip>
  );

  return (
    <aside className="fixed inset-y-0 left-0 z-10 flex flex-col items-center bg-background border-r border-border w-14">
      <Link to="/bions-management-app/bions" className="mt-4 mb-6">
        <img
          src={theme === "dark" ? cultzymeLogoLight : cultzymeLogoDark}
          alt="Cultzyme-Logo"
          className="w-8 h-8"
        />
      </Link>

      <nav className="flex flex-col items-center gap-2 flex-1 w-full">
        <NavLink to="/home" icon={Home} text="Home" />
        <NavLink
          to="/bions-management-app/bions"
          icon={LayoutDashboard}
          text="Bion Dashboard"
        />
        <NavLink to="/new-files" icon={FileText} text="Files" />
        <NavLink to="/settings" icon={Settings} text="Settings" />
      </nav>

      <div className="mb-2 w-full flex justify-center">
        <Tooltip text="Tell us what you think!">
          <a
            href="https://us21.list-manage.com/survey?u=3c9a75d368a60cd350eab1916&id=5b8e6529b8&attribution=false"
            target="_blank"
            rel="noreferrer"
            className={navLinkClass("")}
          >
            <MessageSquare className="h-[1.2rem] w-[1.2rem]" />
          </a>
        </Tooltip>
      </div>

      <div className="mb-2 w-full flex justify-center">
        <Tooltip text="Toggle theme">
          <ThemeToggle className={navLinkClass("")} />
        </Tooltip>
      </div>

      <div className="mb-2 w-full flex justify-center">
        <Tooltip text="Log Out">
          <button onClick={handleLogout} className={navLinkClass("")}>
            <LogOut className="h-[1.2rem] w-[1.2rem]" />
          </button>
        </Tooltip>
      </div>
    </aside>
  );
};

export default MainNavigation;
