import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-hot-toast";
import { setCredentials, logOut } from "../../features/auth/authSlice";
import { getLocalStorageItems } from "../../utils/localStorageUtils";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1`,
  prepareHeaders: (headers, { getState }) => {
    if (headers.has("refreshtoken")) return headers;

    const accessToken = getState().auth.token;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data?.message) toast.error(result.error.data.message);
  if (result?.data?.message) toast.success(result.data.message);

  if (result?.error?.status === 401) {
    const refreshToken = getLocalStorageItems("refreshToken");
    const refreshResult = await baseQuery(
      {
        url: `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1`,
        method: "POST",
        headers: { refreshtoken: refreshToken },
        body: { userName: api.getState().auth.user?.userName },
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      api.dispatch(setCredentials(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const aiApiSlice = createApi({
  reducerPath: "apiTwo",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Documents", "Training"],
  endpoints: (builder) => ({
    getTraining: builder.query({
      query: (trainingDoeId) => `/trainings/does/${trainingDoeId}`,
      providesTags: ["Training"],
    }),
    startDesign: builder.mutation({
      query: ({ trainingDoeId, experimentalDesignId, measureFrequency }) => ({
        url: `/trainings/does/experimental-designs/start`,
        method: "PUT",
        body: { trainingDoeId, experimentalDesignId, measureFrequency },
      }),
      invalidatesTags: ["Training"],
    }),
    generateImpactFactors: builder.mutation({
      query: ({ trainingDoeId }) => ({
        url: `/trainings/does/impact-factors/generate`,
        method: "POST",
        body: { trainingDoeId },
      }),
      invalidatesTags: ["Training"],
    }),
    createExperimentalDesigns: builder.mutation({
      query: ({ trainingDoeId, impactFactorsIntroduction, impactFactors }) => ({
        url: `/trainings/does/experimental-designs/generate`,
        method: "POST",
        body: { trainingDoeId, impactFactorsIntroduction, impactFactors },
      }),
      invalidatesTags: ["Training"],
    }),
    generateExperimentalDesigns: builder.mutation({
      query: ({ trainingDoeId }) => ({
        url: `/trainings/does/experimental-designs/generate`,
        method: "POST",
        body: { trainingDoeId },
      }),
      invalidatesTags: ["Training"],
    }),
  }),
});

export const {
  useGetTrainingQuery,
  useLazyGetTrainingQuery,
  useStartDesignMutation,
  useGenerateImpactFactorsMutation,
  useCreateExperimentalDesignsMutation,
  useGenerateExperimentalDesignsMutation,
} = aiApiSlice;
