import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { Card, CardContent, CardFooter } from "../../components/UI/Shadcn/Card";
import { motion } from "framer-motion";
import { Input } from "../../components/UI/Shadcn/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/UI/Shadcn/Select";
import { Switch } from "../../components/UI/Shadcn/Switch";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  AlertCircle,
  Loader2,
  CheckCircle,
  PlusCircle,
  MessageSquare,
} from "lucide-react";
import { Button } from "../../components/UI/Shadcn/Button";
import { useForm, useController, Controller } from "react-hook-form";
import MicroOrganismCombobox from "./AiComponents/MicroOrganismCombobox";
import MoleculeCombobox from "./AiComponents/MoleculeCombobox";
import ErrorBoundary from "../../components/ErrorBoundary";
import GmoPurposeInput from "./AiComponents/gmoPurposeInput";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/UI/Shadcn/Dialog";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/use-toast";

const InputComponent = memo(({ name, control, onBlur, placeholder }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules:
      name === "productionVolume"
        ? {
            required: "Production volume is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "Please enter a number",
            },
          }
        : {},
  });

  return (
    <div>
      <Input
        {...field}
        onBlur={(e) => {
          field.onBlur();
          onBlur && onBlur(e);
        }}
        className={`w-[150px] h-8 px-2 py-1 text-sm inline-flex items-center ${
          error ? "border-red-500" : ""
        }`}
        placeholder={placeholder || `Enter ${name}`}
        autoComplete="off"
        aria-invalid={error ? "true" : "false"}
        aria-describedby={error ? `${name}-error` : undefined}
      />
      {error && (
        <p className="text-red-500 text-xs mt-1" id={`${name}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
});

InputComponent.displayName = "InputComponent";

const SelectComponent = memo(({ name, options = [], control, disabled }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <Select
        onValueChange={field.onChange}
        value={field.value}
        disabled={disabled}
      >
        <SelectTrigger className="w-[200px] h-8 px-2 py-1 text-sm inline-flex items-center">
          <SelectValue placeholder={`${name}`} />
        </SelectTrigger>
        <SelectContent>
          {options.length > 0 ? (
            options.map((option) => (
              <SelectItem key={`${name}-${option.value}`} value={option.value}>
                {option.description}
              </SelectItem>
            ))
          ) : (
            <SelectItem value="no-options" disabled>
              No options available
            </SelectItem>
          )}
        </SelectContent>
      </Select>
    )}
  />
));

SelectComponent.displayName = "SelectComponent";

const SwitchComponent = memo(
  ({ name, control, formState, setValue, accessToken }) => (
    <div className="inline-flex items-center space-x-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Switch
              checked={field.value === "YES"}
              onCheckedChange={(checked) =>
                field.onChange(checked ? "YES" : "NO")
              }
              aria-label={`Toggle ${name}`}
            />
            {field.value === "YES" && (
              <GmoPurposeInput
                value={formState.gmoPurpose}
                onChange={(value) => setValue("gmoPurpose", value)}
                accessToken={accessToken}
                selectedType={formState.microOrganisms}
              />
            )}
          </>
        )}
      />
    </div>
  )
);

SwitchComponent.displayName = "SwitchComponent";

const TrainingNameModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [trainingName, setTrainingName] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (trainingName.trim()) {
      onSubmit(trainingName);
      setTrainingName("");
      setError("");
    } else {
      setError("Please enter a valid training name.");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Training</DialogTitle>
          <DialogDescription>
            Enter a name for your new training.
          </DialogDescription>
        </DialogHeader>
        <Input
          value={trainingName}
          onChange={(e) => setTrainingName(e.target.value)}
          placeholder="Training Name"
        />
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        <DialogFooter>
          <Button
            onClick={handleSubmit}
            className="bg-primary text-background w-full"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating...
              </>
            ) : (
              "Create"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default function InSilico() {
  const [prompt, setPrompt] = useState("");
  const [placeholders, setPlaceholders] = useState({});
  const [bioreactorModels, setBioreactorModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = useSelector(selectCurrentToken);
  const [hasTrainingData, setHasTrainingData] = useState(null);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isCreatingTraining, setIsCreatingTraining] = useState(false);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      processTypes: "",
      aims: "",
      microOrganisms: "",
      microOrganismsDescription: "",
      baseConditions: "NO",
      gmoPurpose: "",
      bioProcessTargets: "",
      moleculeTypes: "",
      moleculeName: "",
      productionVolume: "",
      bioreactorModels: "",
      processModes: "",
    },
    mode: "onChange",
  });

  const formState = watch();

  const [formStructure, setFormStructure] = useState([]);

  const fetchData = useCallback(
    async (url) => {
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      return response.json();
    },
    [accessToken]
  );

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [promptData, placeholdersData] = await Promise.all([
          fetchData(
            `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/AI_SEARCH_ENG_PROMPT/base-prompt`
          ),
          fetchData(
            `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/ai-search-engines/placeholders`
          ),
        ]);

        if (promptData) setPrompt(promptData.basePrompt);
        if (placeholdersData) setPlaceholders(placeholdersData);

        setLoading(false);
        setIsFirstLoad(false);
      } catch (err) {
        console.error("Error fetching all data:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAllData();
  }, [fetchData]);

  const fetchBioreactorModels = useCallback(
    async (volume) => {
      if (!volume || !accessToken) return;

      try {
        const data = await fetchData(
          `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/bioreactor-models/${volume}`
        );
        setBioreactorModels(data);
      } catch (err) {
        console.error(`Error fetching bioreactor models:`, err);
        setError(err.message);
      }
    },
    [accessToken, fetchData]
  );

  useEffect(() => {
    const parsePrompt = (promptString) => {
      const promptLines = promptString.split("\n");
      return promptLines.map((line) => {
        const regex = /{(select|input|switch):(\w+)}(\[(\w+)==(\w+)\])?/g;
        let match;
        const structure = [];
        let lastIndex = 0;

        while ((match = regex.exec(line)) !== null) {
          if (match.index > lastIndex) {
            structure.push({
              type: "text",
              content: line.slice(lastIndex, match.index),
            });
          }
          const elementType =
            match[2] === "baseConditions" ? "switch" : match[1];
          structure.push({
            type: elementType,
            name: match[2],
            condition:
              match[4] && match[5]
                ? { field: match[4], value: match[5] }
                : undefined,
          });
          lastIndex = regex.lastIndex;
        }

        if (lastIndex < line.length) {
          structure.push({
            type: "text",
            content: line.slice(lastIndex),
          });
        }

        return structure;
      });
    };

    if (prompt) {
      setFormStructure(parsePrompt(prompt));
    }
  }, [prompt]);

  const handleVolumeChange = (e) => {
    const volume = e.target.value;

    if (!/^\d+(\.\d+)?$/.test(volume)) {
      return;
    }

    fetchBioreactorModels(volume);
  };

  const renderFormElement = (element, index) => {
    if (element.condition) {
      const conditionMet =
        formState[element.condition.field] === element.condition.value;
      if (!conditionMet) return null;
    }

    if (
      (element.name === "moleculeTypes" || element.name === "moleculeName") &&
      formState.bioProcessTargets !== "MOLECULE_OF_INTEREST"
    ) {
      return null;
    }

    if (element.name === "gmoPurpose" && formState.baseConditions !== "YES") {
      return null;
    }

    switch (element.type) {
      case "select":
        return (
          <SelectComponent
            key={`${element.name}-${index}`}
            name={element.name}
            options={
              element.name === "bioreactorModels"
                ? bioreactorModels
                : placeholders[element.name] || []
            }
            control={control}
            disabled={false}
          />
        );
      case "input":
        if (element.name === "gmoPurpose") {
          return null;
        }
        if (element.name === "microOrganismsDescription") {
          return (
            <ErrorBoundary key={`${element.name}-${index}`}>
              <MicroOrganismCombobox
                value={formState.microOrganismsDescription}
                onChange={(value) =>
                  setValue("microOrganismsDescription", value)
                }
                accessToken={accessToken}
                selectedMicroOrganism={formState.microOrganisms}
              />
            </ErrorBoundary>
          );
        }
        if (element.name === "moleculeName") {
          return (
            <ErrorBoundary key={`${element.name}-${index}`}>
              <MoleculeCombobox
                value={formState.moleculeName}
                onChange={(value) => setValue("moleculeName", value)}
                accessToken={accessToken}
                selectedMoleculeType={formState.moleculeTypes}
              />
            </ErrorBoundary>
          );
        }
        return (
          <InputComponent
            key={`${element.name}-${index}`}
            name={element.name}
            control={control}
            onBlur={
              element.name === "productionVolume"
                ? handleVolumeChange
                : undefined
            }
            placeholder={
              element.name === "productionVolume" ? "2.5" : undefined
            }
          />
        );
      case "switch":
        return (
          <SwitchComponent
            key={`${element.name}-${index}`}
            name={element.name}
            control={control}
            formState={formState}
            setValue={setValue}
            accessToken={accessToken}
          />
        );
      case "text":
        return !element.content.includes("[") ? (
          <span key={`text-${index}`} className="text-sm mr-2 ">
            {element.content}
          </span>
        ) : null;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    setIsSearching(true);
    const newFormData = {
      processType: formState.processTypes,
      aimType: formState.aims,
      gmoActivated: formState.baseConditions === "YES",
      gmoPurpose: formState.gmoPurpose,
      microOrganismType: formState.microOrganisms,
      microOrganismDescription: formState.microOrganismsDescription,
      bioProcessTargetType: formState.bioProcessTargets,
      moleculeType: formState.moleculeTypes,
      moleculeDescription: formState.moleculeName,
      productionVolume: parseFloat(formState.productionVolume),
      bioreactorModel: formState.bioreactorModels,
      processMode: formState.processModes,
    };

    setFormData(newFormData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/ai-search-engines/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(newFormData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "An error occurred during the search."
        );
      }

      const result = await response.json();
      setHasTrainingData(result);
    } catch (error) {
      console.error("Error during API request:", error);
      setHasTrainingData(false);
      toast({
        title: "Error",
        description: error.message || "An error occurred during the search.",
        variant: "destructive",
      });
    } finally {
      setIsSearching(false);
    }
  };

  const handleCreateTraining = async (trainingName) => {
    if (!formData) {
      console.error("Form data is not available");
      return;
    }

    setIsCreatingTraining(true);

    const trainingData = {
      ...formData,
      trainingDoeName: trainingName,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(trainingData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create new training.");
      }

      const result = await response.json();
      console.log("New training created:", result);
      setShowTrainingModal(false);
      setHasTrainingData(true);

      navigate(`/trainings/${result.trainingDoeId}`);
    } catch (error) {
      console.error("Error creating new training:", error);
      toast({
        title: "Error",
        description:
          error.message || "Failed to create new training. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsCreatingTraining(false);
    }
  };

  const handleCreateChat = async () => {
    setIsCreatingChat(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create chat.");
      }

      const result = await response.json();
      console.log("Chat created:", result);
      navigate(`/chatbot/${result.chatId}`);
    } catch (error) {
      console.error("Error creating chat:", error);
      toast({
        title: "Error",
        description:
          error.message || "Failed to create chat. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsCreatingChat(false);
    }
  };

  if (isFirstLoad) {
    return (
      <div className="flex items-center justify-center h-screen bg-background">
        <div className="text-center">
          <Loader2 className="h-12 w-12 animate-spin text-primary mx-auto" />
          <p className="mt-4 text-lg font-medium text-primary">
            Loading data...
          </p>
        </div>
      </div>
    );
  }

  if (error)
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-background to-background/80 p-6 md:p-10 ">
      <motion.header
        className="text-center mb-12"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl text-primary mb-6">
          In Silico
        </h1>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <p className="text-lg text-muted-foreground max-w-3xl mx-auto leading-relaxed">
            Welcome to the future of biotech AI. This is where innovation meets
            simplicity. Train your own AI, powered by real bioprocess data,
            optimizing every step effortlessly, and designing solutions that
            redefine what's possible.
          </p>
          <p className="mt-4 text-lg font-semibold text-primary/90 max-w-2xl mx-auto">
            Are you ready to take the next leap? Let's get started.
          </p>
        </motion.div>
      </motion.header>
      <Card className="max-w-4xl mx-auto mb-6 shadow-lg border-primary/10">
        <CardContent className="p-6">
          <div className="space-y-4 text-sm">
            {formStructure.map((line, lineIndex) => (
              <motion.div
                key={`line-${lineIndex}`}
                className="flex flex-wrap items-center gap-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * lineIndex, duration: 0.5 }}
              >
                {line.map((element, elementIndex) =>
                  renderFormElement(element, `${lineIndex}-${elementIndex}`)
                )}
              </motion.div>
            ))}
          </div>
        </CardContent>
        <CardFooter className="bg-primary/5 p-4 rounded-b-lg">
          <Button
            size="lg"
            className="w-full bg-primary text-primary-foreground hover:bg-primary/90 transition-colors duration-200"
            onClick={handleSubmit}
            disabled={isSearching}
          >
            {isSearching ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Searching...
              </>
            ) : (
              "Search"
            )}
          </Button>
        </CardFooter>
      </Card>
      {hasTrainingData !== null && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-6 flex justify-center"
        >
          <div className="w-[755px] max-w-4xl">
            {hasTrainingData ? (
              <Card className="border-primary bg-card">
                <CardContent className="pt-6">
                  <Alert variant="default" className="border-0 bg-transparent">
                    <CheckCircle className="h-5 w-5 text-primary" />
                    <AlertTitle className="text-lg font-semibold  text-primary">
                      Success
                    </AlertTitle>
                    <AlertDescription className="text-primary mt-2">
                      Search completed successfully! Your results are ready.
                    </AlertDescription>
                  </Alert>
                </CardContent>
                <CardFooter className="flex justify-end pb-6">
                  <Button
                    className="bg-primary hover:bg-primary/90 text-white"
                    onClick={handleCreateChat}
                    disabled={isCreatingChat}
                  >
                    {isCreatingChat ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Creating Chat...
                      </>
                    ) : (
                      <>
                        <MessageSquare className="mr-2 h-4 w-4" />
                        Create Chat
                      </>
                    )}
                  </Button>
                </CardFooter>
              </Card>
            ) : (
              <Card className="border-primary bg-card">
                <CardContent className="pt-6">
                  <Alert variant="default" className="border-0 bg-transparent">
                    <AlertCircle className="h-5 w-5 text-primary" />
                    <AlertTitle className="text-lg font-semibold text-muted-foreground">
                      No Data Found
                    </AlertTitle>
                    <AlertDescription className="text-muted-foreground mt-2">
                      We couldn't find any existing training for your search
                      criteria. Would you like to create a new training?
                    </AlertDescription>
                  </Alert>
                </CardContent>
                <CardFooter className="flex justify-end pb-6">
                  <Button
                    onClick={() => setShowTrainingModal(true)}
                    className="bg-primary hover:bg-primary/90 text-white"
                  >
                    <PlusCircle className="mr-2 h-4 w-4" />
                    Create New Training
                  </Button>
                </CardFooter>
              </Card>
            )}
          </div>
        </motion.div>
      )}
      <TrainingNameModal
        isOpen={showTrainingModal}
        onClose={() => setShowTrainingModal(false)}
        onSubmit={handleCreateTraining}
        isLoading={isCreatingTraining}
      />
    </div>
  );
}
