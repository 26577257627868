import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";
import { getLocalStorageItems } from "../../utils/localStorageUtils";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL + "/cultzyme-api/v1",
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.token;
    console.log("Accessing API with token:", accessToken);
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log("Token expired, attempting to refresh..."); // Log al recibir error 401
    const refreshToken = getLocalStorageItems("refreshToken");
    const accessToken = api.getState().auth.token;

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: "/auth/refreshtoken",
          method: "POST",
          body: { refreshToken, userName: api.getState().auth.user.userName },
        },
        api,
        extraOptions
      );

      if (refreshResult?.data) {
        console.log("Token refresh successful:", refreshResult.data); // Log al renovar el token
        api.dispatch(
          setCredentials({
            accessToken: refreshResult.data.accessToken,
            refreshToken: refreshResult.data.refreshToken,
            userName: api.getState().auth.user.userName,
          })
        );

        // Confirmar que se actualizó el token en el estado
        console.log("Updated accessToken in state:", api.getState().auth.token);

        result = await baseQuery(args, api, extraOptions);
      } else {
        console.log("Token refresh failed. Logging out..."); // Log si falla la renovación
        api.dispatch(logOut());
      }
    } else {
      console.log("No refresh token available. Logging out..."); // Log si no hay refresh token
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiOne",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Process"],
  endpoints: (builder) => ({
    getData: builder.query({
      query: (endpoint) => ({
        url: endpoint,
        method: "GET",
      }),
    }),
    postData: builder.mutation({
      query: ({ endpoint, body }) => ({
        url: endpoint,
        method: "POST",
        body: body,
      }),
    }),
    updateData: builder.mutation({
      query: ({ endpoint, body }) => ({
        url: endpoint,
        method: "PUT",
        body: body,
      }),
    }),
    deleteData: builder.mutation({
      query: (endpoint) => ({
        url: endpoint,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetDataQuery,
  usePostDataMutation,
  useUpdateDataMutation,
  useDeleteDataMutation,
} = apiSlice;
