import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectCurrentToken } from "../../features/auth/authSlice";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/UI/Shadcn/Card";
import { Loader2, AlertCircle, ChevronRight, Trash2 } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/UI/Shadcn/Table";
import { Button } from "../../components/UI/Shadcn/Button";
import { Badge } from "../../components/UI/Shadcn/Badge";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../components/UI/Shadcn/Breadcrumb";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/UI/Shadcn/Dialog";
import { useToast } from "../../hooks/use-toast";

export default function Trainings() {
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    trainingId: null,
  });

  const accessToken = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const { toast } = useToast();

  const fetchData = useCallback(
    async (url) => {
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return response.json();
    },
    [accessToken]
  );

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const data = await fetchData(
          `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`
        );
        setTrainings(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTrainings();
  }, [fetchData]);

  const openTrainingDetails = (trainingDoeId) => {
    navigate(`/trainings/${trainingDoeId}`);
  };

  const confirmDelete = (trainingDoeId) => {
    setDeleteConfirmation({ isOpen: true, trainingId: trainingDoeId });
  };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, trainingId: null });
  };

  const deleteTraining = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify([
            { trainingDoeId: deleteConfirmation.trainingId },
          ]),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setTrainings(
        trainings.filter(
          (training) => training.trainingDoeId !== deleteConfirmation.trainingId
        )
      );
      toast({
        title: "Training Deleted",
        description: "The training has been successfully deleted.",
        variant: "success",
      });
    } catch (err) {
      setError(err.message);
      toast({
        title: "Error",
        description: "Failed to delete the training. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
      setDeleteConfirmation({ isOpen: false, trainingId: null });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-5 w-5" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto py-10">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/new-files">
              Files
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Trainings</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Card className="mt-6">
        <CardHeader>
          <CardTitle>Trainings</CardTitle>
          <CardDescription>
            Overview of all your training sessions
          </CardDescription>
        </CardHeader>
        <CardContent>
          {trainings.length === 0 ? (
            <div className="text-center py-6">
              <h3 className="text-2xl font-semibold mb-2">
                No Trainings Available
              </h3>
              <p className="text-muted-foreground">
                You currently don't have any trainings. Trainings will appear
                here once they are created.
              </p>
            </div>
          ) : (
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="font-semibold">Name</TableHead>
                    <TableHead className="font-semibold">Start Date</TableHead>
                    <TableHead className="font-semibold">Finish Date</TableHead>
                    {/* <TableHead className="font-semibold">State</TableHead> */}
                    <TableHead className="font-semibold">
                      Process Type
                    </TableHead>
                    <TableHead className="font-semibold">Aim Type</TableHead>
                    <TableHead className="text-right font-semibold">
                      Actions
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {trainings.map((training) => (
                    <TableRow key={training.trainingDoeId}>
                      <TableCell className="font-medium">
                        {training.trainingDoeName}
                      </TableCell>
                      <TableCell>
                        {new Date(training.startDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {training.finishDate
                          ? new Date(training.finishDate).toLocaleDateString()
                          : "In Progress"}
                      </TableCell>
                      {/* <TableCell>
                        <Badge
                          variant={
                            training.state === "Completed"
                              ? "success"
                              : "secondary"
                          }
                        >
                          {training.state}
                        </Badge>
                      </TableCell> */}
                      <TableCell>{training.processTypeDescription}</TableCell>
                      <TableCell>{training.aimTypeDescription}</TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end space-x-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              openTrainingDetails(training.trainingDoeId)
                            }
                          >
                            View Details
                            <ChevronRight className="ml-2 h-4 w-4" />
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() =>
                              confirmDelete(training.trainingDoeId)
                            }
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={deleteConfirmation.isOpen} onOpenChange={cancelDelete}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this training? This action cannot
              be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={cancelDelete}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={deleteTraining}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                "Delete"
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
